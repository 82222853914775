* {
  box-sizing: border-box;
}
.datetime-picker {
  position: relative;
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 250px;
  box-sizing: content-box;
  z-index: 100;
}
.datetime-picker .calendar .calendar-nav {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  min-height: 32px;
  padding: 4px;
}
.datetime-picker .calendar .calendar-nav button {
  background: none;
  border: 0;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}
.datetime-picker .calendar .calendar-nav button .fa {
  font-size: 18px;
}
.datetime-picker .calendar .calendar-nav button:hover {
  background-color: #f0f0f0;
}
.datetime-picker .calendar .calendar-nav .current-date {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 8px;
  cursor: pointer;
}
.datetime-picker .calendar .calendar-nav .current-date:hover {
  background-color: #f0f0f0;
}
.datetime-picker .calendar .calendar-nav .current-date.disabled {
  cursor: default;
}
.datetime-picker .calendar table {
  display: block;
  margin: 4px;
}
.datetime-picker .calendar table th,
.datetime-picker .calendar table td {
  padding: 0;
}
.datetime-picker .calendar table thead {
  display: block;
  margin: 8px 0 3px;
}
.datetime-picker .calendar table thead tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table thead th {
  color: #FF9A19;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: .8em;
  font-weight: 400;
}
.datetime-picker .calendar table tbody {
  display: block;
}
.datetime-picker .calendar table tbody tr {
  display: flex;
  flex-wrap: wrap;
}
.datetime-picker .calendar table tbody tr td {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
}
.datetime-picker .calendar table tbody tr td:hover {
  background-color: #f0f0f0;
}
.datetime-picker .calendar table tbody tr td.prev,
.datetime-picker .calendar table tbody tr td.next,
.datetime-picker .calendar table tbody tr td.disabled {
  color: #dedede;
}
.datetime-picker .calendar table tbody tr td.prev:hover,
.datetime-picker .calendar table tbody tr td.next:hover,
.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: #c5c5c5;
}
.datetime-picker .calendar table tbody tr td.disabled:hover {
  color: #dedede;
  background-color: transparent;
  cursor: not-allowed;
}
.datetime-picker .calendar table tbody tr td.now {
  color: #5cc4ef;
  font-weight: 400;
}
.datetime-picker .calendar table tbody tr td.selected {
  background-color: #5cc4ef;
  color: #fff;
  font-weight: 400;
}
.datetime-picker .calendar table tbody tr td.selected:hover {
  background-color: #53c1ee;
  color: #fff;
}
.datetime-picker .calendar table tbody tr td.selected.start {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.end {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.datetime-picker .calendar table tbody tr td.selected.start.end {
  border-radius: 4px;
}
.datetime-picker .calendar table tbody tr td.between {
  background-color: #f5fbfe;
  border-radius: 0;
}
.datetime-picker .calendar .calendar-days table tbody tr td {
  width: 14.28571429%;
}
.datetime-picker .calendar .calendar-months table tbody tr td {
  width: 33.33333333%;
  height: 40px;
}
.datetime-picker .calendar .calendar-years table tbody tr td {
  width: 25%;
  height: 60px;
}
.datetime-picker .time {
  border-top: 1px solid #efefef;
  padding: 4px;
  display: flex;
  align-items: center;
  position: relative;
}
.datetime-picker .time .show-time {
  display: flex;
  align-items: center;
  flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}
.datetime-picker .time .show-time .text {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}
.datetime-picker .time .show-time .separater {
  margin: 0 2px 3px;
  line-height: 1;
}
.datetime-picker .time .sliders {
  flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}
.datetime-picker .time .sliders .slider-text {
  display: none;
}
.datetime-picker .time .sliders .slider {
  display: flex;
  align-items: center;
  font-size: 11px;
  height: 17px;
  background-image: linear-gradient(to right, #dedede, #dedede);
  background-position: 0 50%;
  background-size: 100% 1px;
  background-repeat: no-repeat;
}
.datetime-picker .time .sliders .slider .handle {
  width: 12px;
  height: 12px;
  background-color: #fff;
  border: 1px solid #dedede;
  border-radius: 3px;
  cursor: pointer;
}
.datetime-picker .time .sliders .slider .handle:hover {
  border-color: #b8b8b8;
}
.datetime-picker .time .sliders .slider .handle:focus,
.datetime-picker .time .sliders .slider .handle:active {
  background-color: #5cc4ef;
  border-color: #5cc4ef;
}
.datetime-picker .shortcuts-bar {
  border-bottom: 1px solid #efefef;
  padding: 8px;
}
.datetime-picker .shortcuts-bar .btn {
  border: 0;
  background: none;
  cursor: pointer;
  border-radius: 2px;
  padding: 2px 4px;
  outline: none;
}
.datetime-picker .shortcuts-bar .btn:hover {
  background-color: #f0f0f0;
}
.datetime-picker .shortcuts-bar .btn:last-child {
  float: right;
}
.datetime-picker .shortcuts-bar .btn:not(:first-child) {
  margin-left: 5px;
}
.datetime-range-picker {
  border: 1px solid #dbdbdb;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: content-box;
  z-index: 100;
}
.datetime-trigger {
  position: relative;
}
.datetime-trigger .datetime-picker {
  position: absolute;
  top: 100%;
}
.datetime-range-trigger {
  position: relative;
}
.datetime-range-trigger .datetime-range-picker {
  position: absolute;
  top: 100%;
}
.datetime-picker-popup,
.datetime-range-picker-popup {
  margin-top: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}
.datetime-picker-popup:before,
.datetime-range-picker-popup:before {
  content: "";
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
  left: 10px;
  top: -6px;
  transform: rotate(315deg);
}
